/* stylelint-disable at-rule-no-unknown */

@import url("nprogress/nprogress.css");
@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

html,
body,
#root {
  min-height: 100%;
  width: 100%;
}
